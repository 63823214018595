<template>
  <div>
    <header
      style="height: 44px"
      class="d-flex justify-end align-center fill-width px-10"
    >
      <div class="d-flex align-center fill-height" @click="skip">
        <span class="rixgo-bold font-g14-20 gray500--text pr-2">건너뛰기</span>
        <img
          src="../../../assets/images/arrowNextGray.svg"
          style="width: 16px; height: 16px; flex-shrink: 0"
        />
      </div>
    </header>

    <section class="swiper-container">
      <Flicking
        ref="flicking"
        :options="{
          moveType: ['strict', { count: 1 }],
          circular: true,
          horizontal: true,
          interruptable: true,
          changeOnHold: true,
          panelsPerView: 1,
          defaultIndex: 0,
        }"
        @changed="changeHandler"
      >
        <div
          v-for="(value, index) in swiperInfoArray"
          :key="index"
          class="swiper-contents"
        >
          <Lottie
            :src="require(`../../../assets/lottie/${value.lottie}.json`)"
            style="width: 160px; height: 160px';"
            :options="{ autoplay: true, loop: true }"
            @animCreated="animCreated"
          />
          <p
            class="rixgo-regluar font-g14-20 gray500--text mt-3"
            v-html="value.text"
          />
        </div>
      </Flicking>
      <div class="pagination-container">
        <span
          v-for="(value, index) in swiperInfoArray"
          :key="index"
          class="pagination"
          :class="index === carouselIndex && 'active-carousel'"
          @click="onClickPagination(index)"
        />
      </div>
    </section>

    <section class="rixgo-bold font-g14-22 gray900--text mt-10 text-center">
      이벤트 / 프로모션 / 혜택 등 유용한 정보를<br />
      문자, 이메일로 받으실 수 있습니다.
    </section>

    <section class="gradation" />
    <section class="btn-container text-center fill-width">
      <div
        class="rixgo-bold font-g14-20 text-decoration-underline gray500--text cursor-pointer fill-width"
        style="padding: 16px"
        @click="toDetail"
      >
        자세히 보기
      </div>
      <CellookButton
        style="height: 52px; width: 100%"
        textColor="white"
        button-title-text-class="rixgo-bold font-g14-20"
        class="fill-width blue mt-4 cursor-pointer"
        buttonTitle="마케팅 수신 동의하기"
        @click="agree"
      />
    </section>
  </div>
</template>

<script>
import Lottie from '@/components/common/lottie/LottieView.vue'
import { destroyLottieAnim } from '@/plugins/util/lottie'
import CellookButton from '@/components/common/buttons/CellookButton.vue'
import { PutAdConsent } from '@/api/accountAPI/UsersAPI'
import { Flicking } from '@egjs/vue-flicking'

export default {
  components: {
    CellookButton,
    Lottie,
    Flicking,
  },
  data() {
    return {
      carouselIndex: 0,
      animArr: [],
      swiperInfoArray: [
        {
          text: `내 취향 어떻게 알았지?<br/> 성별/나이 기반 개인화 상품 추천`,
          lottie: 'woman',
        },
        {
          text: '이벤트, 프로모션, 혜택 등<br/> 유용한 정보를 바로 알려드려요.',
          lottie: 'gift',
        },
        {
          text: '신상부터 인기 상품까지,<br/> 할인 상품 정보를 알려드려요.',
          lottie: 'sale',
        },
      ],
    }
  },
  beforeDestroy() {
    this.animArr.forEach((v) => {
      destroyLottieAnim(v)
    })
  },
  methods: {
    animCreated(anim) {
      if (anim) {
        this.animArr.push(anim)
      }
    },
    goToSignInComplete() {
      this.$router.replace('/signInComplete')
    },
    skip() {
      PutAdConsent({ emailYN: 'N', smsYN: 'N' })
        .then(() => {
          console.info('마케팅 수신동의 건너뛰기 성공')
          this.$tracking('join_mktpushguide_skipbtn')
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.goToSignInComplete()
        })
    },
    agree() {
      PutAdConsent({ emailYN: 'Y', smsYN: 'Y' })
        .then(() => {
          console.info('마케팅 수신동의 성공')
          this.$tracking('join_mktpushguide_consentbtn')
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.goToSignInComplete()
        })
    },
    toDetail() {
      this.$tracking('join_mktpushguide_viewmorebtn')
      this.$router.push('mypage/push-notice')
    },
    changeHandler({ index }) {
      this.carouselIndex = index
    },
    onClickPagination(index) {
      const flicking = this.$refs.flicking
      flicking.stopAnimation()
      flicking.moveTo(index, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.swiper-pagination-bullet-active) {
  background-color: #003399;
}

.swiper-container {
  position: relative;
  background: #f7f8f9;
  height: 284px;
  border-radius: 16px;
  margin: 28px 20px 0 20px;
}

.swiper-contents {
  margin: 20px;
  text-align: center;
}

.btn-container {
  position: fixed;
  bottom: 0px;
  padding: 8px 20px 20px 20px;
  background-color: #fff;
  z-index: 1;
  max-width: 600px;
}

.gradation {
  position: fixed;
  width: 100%;
  height: 20px;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  max-width: 600px;
  bottom: 140px;
  z-index: 1;
}

.pagination-container {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  z-index: 1;
}

.pagination {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d8d9dc;
  cursor: pointer;
}

.active-carousel {
  background: #039;
}
</style>
