<template>
  <MarketingAgree />
</template>
<script>
import MarketingAgree from '../../views/login/MarketingAgree.vue'

export default {
  components: {
    MarketingAgree,
  },
}
</script>
