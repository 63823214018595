var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"d-flex justify-end align-center fill-width px-10",staticStyle:{"height":"44px"}},[_c('div',{staticClass:"d-flex align-center fill-height",on:{"click":_vm.skip}},[_c('span',{staticClass:"rixgo-bold font-g14-20 gray500--text pr-2"},[_vm._v("건너뛰기")]),_c('img',{staticStyle:{"width":"16px","height":"16px","flex-shrink":"0"},attrs:{"src":require("../../../assets/images/arrowNextGray.svg")}})])]),_c('section',{staticClass:"swiper-container"},[_c('Flicking',{ref:"flicking",attrs:{"options":{
        moveType: ['strict', { count: 1 }],
        circular: true,
        horizontal: true,
        interruptable: true,
        changeOnHold: true,
        panelsPerView: 1,
        defaultIndex: 0,
      }},on:{"changed":_vm.changeHandler}},_vm._l((_vm.swiperInfoArray),function(value,index){return _c('div',{key:index,staticClass:"swiper-contents"},[_c('Lottie',{staticStyle:{"width":"160px","height":"160px'"},attrs:{"src":require(`../../../assets/lottie/${value.lottie}.json`),"options":{ autoplay: true, loop: true }},on:{"animCreated":_vm.animCreated}}),_c('p',{staticClass:"rixgo-regluar font-g14-20 gray500--text mt-3",domProps:{"innerHTML":_vm._s(value.text)}})],1)}),0),_c('div',{staticClass:"pagination-container"},_vm._l((_vm.swiperInfoArray),function(value,index){return _c('span',{key:index,staticClass:"pagination",class:index === _vm.carouselIndex && 'active-carousel',on:{"click":function($event){return _vm.onClickPagination(index)}}})}),0)],1),_vm._m(0),_c('section',{staticClass:"gradation"}),_c('section',{staticClass:"btn-container text-center fill-width"},[_c('div',{staticClass:"rixgo-bold font-g14-20 text-decoration-underline gray500--text cursor-pointer fill-width",staticStyle:{"padding":"16px"},on:{"click":_vm.toDetail}},[_vm._v(" 자세히 보기 ")]),_c('CellookButton',{staticClass:"fill-width blue mt-4 cursor-pointer",staticStyle:{"height":"52px","width":"100%"},attrs:{"textColor":"white","button-title-text-class":"rixgo-bold font-g14-20","buttonTitle":"마케팅 수신 동의하기"},on:{"click":_vm.agree}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"rixgo-bold font-g14-22 gray900--text mt-10 text-center"},[_vm._v(" 이벤트 / 프로모션 / 혜택 등 유용한 정보를"),_c('br'),_vm._v(" 문자, 이메일로 받으실 수 있습니다. ")])
}]

export { render, staticRenderFns }